<template>
  <div>
    <Headbar />
    <router-view />
    <Footerbar />
  </div>
</template>

<script>
import Headbar from "./Headbar";
import Footerbar from "./Footerbar";

export default {
  name: "Layout",
  components: {
    Headbar,
    Footerbar,
  },
};
</script>
