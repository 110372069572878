<template>
  <v-bottom-navigation
    app
    :value="activeBtn"
    @change="change"
    grow
    color="green darken-2"
  >
    <v-btn>
      <span>我的</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn>
      <span>打卡</span>
      <v-icon>mdi-map-marker</v-icon>
    </v-btn>

    <v-btn>
      <span>统计</span>
      <v-icon>mdi-chart-line</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "Footerbar",

  data() {
    return {
      activeBtn: 0,
    };
  },
  mounted() {
    this.check_path();
  },
  methods: {
    check_path() {
      let path = this.$route.path;
      if (path === "/home") this.activeBtn = 0;
      else if (path === "/overview") this.activeBtn = 1;
      else if (path === "/calender") this.activeBtn = 2;
    },
    change(data) {
      if (data === 0) this.$router.push({ name: "home" });
      else if (data === 1) this.$router.push({ name: "overview" });
      else if (data === 2) this.$router.push({ name: "calender" });
    },
  },
};
</script>
