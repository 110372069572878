<template>
  <v-app-bar app>
    <v-icon>mdi-vuetify</v-icon>
    <v-toolbar-title>欢迎来到 一粒麦子课时系统</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
          <v-icon color="teal"> mdi-account-supervisor </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in items" :key="i" @click="logout">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <span>{{ userName }}</span>
  </v-app-bar>
</template>

<script>
import { Logout } from "@/api";

export default {
  name: "Headbar",
  data() {
    return {
      userName: localStorage.getItem("user"),
      items: [{ title: "退出登录" }],
    };
  },
  methods: {
    logout() {
      Logout().then(() => {
        localStorage.clear();
        sessionStorage.clear();

        this.$router.push({
          name: "login",
        });
      });
    },
  },
};
</script>
